import React from "react"

function TitleCard({title, image}) {
  return (
    <header className="header" style={{ backgroundImage: `url("${image}")` }}>
      <h1>{title}</h1>
    </header>
  )
}

export default TitleCard
