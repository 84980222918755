import React, { useState } from "react"
import * as R from "ramda"
import css from "../css/styles.css"
import Img from "gatsby-image"
import Header from "../components/Header"
import FullSizeImage from "../components/FullSizeImage"
import TitleCard from "../components/TitleCard"
import Section from "../components/Section"

export default function Home({ data }) {
  const [selectedImage, setSelectedImage] = useState()
  const destructureFrontMatter = R.map(x => ({ ...x.frontmatter }))
  const galleries = R.compose(
    destructureFrontMatter,
    R.pathOr([], ["allMarkdownRemark", "nodes"])
  )(data)
  const images = R.compose(R.pathOr([], ["allFile", "nodes"]))(data)
  return (
    <>
      <Header />
      {selectedImage && (
        <FullSizeImage img={selectedImage} setImage={setSelectedImage} />
      )}
      {galleries.map(({ title, isTitleCard, image, filePath }) => {
        const gallery = images.filter(g => g.relativeDirectory === filePath)
        return isTitleCard ? (
          <TitleCard
            title={title}
            image={image.childImageSharp.fluid.src}
            key={title}
          />
        ) : (
          <Section
            title={title}
            image={image.childImageSharp.fluid.src}
            key={title}
          >
            {gallery &&
              gallery.length > 0 &&
              gallery.map(img => {
                return (
                  <div
                    className="imageTrigger"
                    onClick={() => setSelectedImage(img.childImageSharp)}
                  >
                    <Img
                      fluid={img.childImageSharp.fluid}
                      key={img.childImageSharp.fluid.src}
                    />
                  </div>
                )
              })}
          </Section>
        )
      })}
    </>
  )
}

export const pageQuery = graphql`
  query Gallery {
    allMarkdownRemark(sort: { fields: frontmatter___isTitleCard }) {
      nodes {
        frontmatter {
          title
          isTitleCard
          filePath
          image {
            childImageSharp {
              fluid(maxWidth: 3840, quality: 80) {
                src
                srcSet
              }
            }
          }
        }
      }
    }
    allFile {
      nodes {
        relativeDirectory
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 3840, quality: 80) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 3840, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
