import React, { useEffect, useState } from "react"
const useProgressiveImage = src => {
  const [sourceLoaded, setSourceLoaded] = useState(null)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setSourceLoaded(src)
  }, [src])

  return sourceLoaded
}

export default function Section({
  image,
  title,
  children,
  classNames = ["gallery"],
}) {
  useEffect(() => {
    function handleResize() {
      setPosition(0)
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const loadedImage = useProgressiveImage(image)
  const [position, setPosition] = useState(0)

  return (
    <section
      className="panelWrapper"
      style={{ transform: `translate(${position}px, 0)` }}
      onClick={() => setPosition(-window.innerWidth)}
    >
      <div className="category panel">
        <div
          style={{ backgroundImage: `url(${loadedImage})` }}
          className="content"
        >
          <h1>{title}</h1>
        </div>
      </div>
      <div className="panel">
        <div className={` ${classNames.join(" ")}`}>{children}</div>
      </div>
    </section>
  )
}
