import React, { useState } from "react"
import Img from "gatsby-image"

function FullSizeImage({ img, setImage }) {
  return (
    <div className="fullsize-image" onClick={() => setImage(null)}>
      <Img fixed={img.fixed} key={img.fixed.src} />
    </div>
  )
}

export default FullSizeImage
